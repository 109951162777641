/* eslint-disable */
import axios from "axios";
import sanitizeHTML from "sanitize-html";
import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Tokyo");
dayjs.locale("ja");
import { useStore } from "@/stores/index";

export const isSp = () => {
  if (navigator.userAgentData && navigator.userAgentData.mobile) {
    return true;
  }
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
    return true;
  }
  return false;
};

export const toBrHtml = (str) => {
  str = str || "";
  str = sanitizeHTML(str);
  if (!str.match(/\r?\n/g)) {
    return str;
  }
  return str.replace(/\r?\n/g, "<br>");
};

// 日付の内部管理は yyyy-MM-dd で統一する
export const toYmd = (str) => {
  return dayjs(str).local().format("YYYY-MM-DD");
};
export const toYmdHisFromUnixTime = (n) => {
  return dayjs.unix(n).local().format("YYYY-MM-DDTHH:mm:ss");
};

// 表示用は yyyy/mm/dd 形式
export const toDisplayDate = (str) => {
  return dayjs(str).local().format("YYYY/MM/DD");
};
export const toDisplayDateTime = (str) => {
  return dayjs(str).local().format("YYYY/MM/DD HH:mm");
};
export const toDisplayTime = (str) => {
  // console.log(str);
  return dayjs(str).local().format("HH:mm");
};
export const toUnixTime = (str) => {
  return dayjs(str).local().unix();
};

export const getUploadUrl = async (uploadFile, imgFlg) => {
  try {
    if (uploadFile != null) {
      // await axios.get("/api/sanctum/csrf-cookie");
      const formData = new FormData();
      formData.append("file", uploadFile);
      const url = imgFlg ? "/api/file/upload-img" : "/api/file/upload";
      const res = await axios.post(url, formData);
      if (res && res.data) {
        return res.data;
      }
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const getUploadImgUrl = async (uploadFile) => {
  return await getUploadUrl(uploadFile, true);
};

export const getDataUrl = async (file) => {
  // Return a promise per file
  if (!file) {
    return null;
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        // Resolve the promise with the response value
        resolve(e.target.result);
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const convertBrHtml = (str) => {
  str = str || "";
  if (!str.match(/\r?\n/g)) {
    return str;
  }
  // タグを無効化
  str = sanitizeHTML(str);
  return str.replace(/\r?\n/g, "<br>");
};

// Vue DatePicker
export const getDayClass = (date) => {
  const store = useStore();
  const holidays = store?.holidays || [];
  const ymd = dayjs(date).format("YYYY-MM-DD");
  const res = holidays.filter((e) => e.holidayDate == ymd);
  if (res && res.length > 0) {
    return "dp__holiday";
  }
  return "";
};

export const getEventCategoryList = () => {
  const store = useStore();
  const nameKeys = store?.nameKeys || [];
  const list = nameKeys.filter((e) => e.type == "event_category");
  list.sort((a, b) => a.orderNo - b.orderNo);
  return list;
};

export const getEcList = () => {
  const store = useStore();
  const nameKeys = store?.nameKeys || [];
  const list = nameKeys.filter((e) => e.type == "ec");
  list.sort((a, b) => a.orderNo - b.orderNo);
  return list;
};

export const getEcHbaList = () => {
  const store = useStore();
  const nameKeys = store?.nameKeys || [];
  const list = nameKeys.filter((e) => e.type == "echba");
  list.sort((a, b) => a.orderNo - b.orderNo);
  return list;
};

export const isHbaCode = (code) => {
  const store = useStore();
  const nameKeys = store?.nameKeys || [];
  const list = nameKeys.filter((e) => e.type == "hba" && e.name == code);
  return list.length > 0;
};


export const toCategoryName = (key) => {
  const store = useStore();
  const nameKeys = store?.nameKeys || [];
  const list = nameKeys.filter((e) => e.type == "event_category" && e.k == key);
  return list?.at(0)?.name;
};


// ファイルをダウンロードする
export const doDownloadFile = async (url, name) => {
  try {
    // ファイルを取得する
    const response = await axios.get(url, {
      responseType: 'blob'
    });

    // Blob URLを作成する
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

    // ダウンロード用のリンクを作成して設定する
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', name);

    // リンクをbodyに追加し、クリックしてダウンロードを開始する
    document.body.appendChild(link);
    link.click();

    // ダウンロードが完了したらリンクを削除する
    document.body.removeChild(link);
  } catch (error) {
    // エラーハンドリング
    console.error('ダウンロード中にエラーが発生しました:', error);
  }
};
