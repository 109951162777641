/* eslint-disable */
import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
import { router } from "./router";
import { createPinia } from "pinia";
import { useStore } from "@/stores/index";
import { isSp } from "@/common";

if (isSp()) {
  window.addEventListener(
    "load",
    () => {
      const e = document.head.querySelectorAll('link[rel="stylesheet"]');
      e[0].href = "/asset/css/style-sp.css?v=20240701";
    },
    false
  );
}

// src/js/components/adjustviewport.js
const AdjustViewsport = (minwidth = 360) => {
  const viewport = document.querySelector('meta[name="viewport"]');
  const switchViewport = () => {
    const value =
      window.outerWidth > minwidth
        ? "width=device-width,initial-scale=1"
        : "width=" + minwidth;
    if (viewport.getAttribute("content") !== value) {
      viewport.setAttribute("content", value);
    }
  };
  window.addEventListener("resize", switchViewport, false);
  switchViewport();
};
AdjustViewsport();

// axios設定
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    const status = error.response?.status;
    const store = useStore();
    store.redirectUrl = null;
    if (status == 400) {
      if (error.response?.data?.maintenance == "1") {
        // 以降の処理を中断し、メンテナンス画面へ遷移する
        location.href = "/maintenance";
      } else if (error.response?.data?.notuse == "1") {
        store.redirectUrl = "/error?notuse=1";
      }
    }
    return Promise.reject(error);
  }
);

// 認証チェック
router.beforeEach(async (to, from, next) => {
  const store = useStore();
  // 各部品のチェックは matched を用いる
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAuthAdmin = to.matched.some(
    (record) => record.meta.requiresAuthAdmin
  );
  if (requiresAuth || requiresAuthAdmin) {
    await store.loadMe();
    if (store.me) {
      if (requiresAuth) {
        next();
      } else if (requiresAuthAdmin && store.me?.admin == "1") {
        next();
      } else {
        next("/login");
      }
    } else {
      if (store.redirectUrl) {
        next(store.redirectUrl);
      } else {
        // 非ログイン時、URL保持
        sessionStorage.setItem("redirect_path", to.path);
        next("/login");
      }
    }
  } else {
    next();
  }
});

const defaultTitle = 'BOOKSHELF';
router.afterEach((to) => {
  const title = to.meta.title ? `${to.meta.title} | ${defaultTitle}` : defaultTitle;
  document.title = title;
})

const app = createApp(App).use(router).use(createPinia());
router.isReady().then(() => {
  app.mount("#app");
});
