import { createRouter, createWebHistory } from "vue-router";

export const routerHistory = createWebHistory();

export const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: "/",
      component: () => import("@/views/Home.vue"),
      meta: { requiresAuth: true, title: "イベント一覧" },
    },
    {
      path: "/error",
      component: () => import("@/views/Error.vue"),
    },
    {
      path: "/maintenance",
      component: () => import("@/views/Maintenance.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/event/:id([0-9]+)",
      component: () => import("@/views/EventDetail.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/event/:id([0-9]+)/edit",
      component: () => import("@/views/EventEdit.vue"),
      meta: { requiresAuth: true },
    },
    // 予約登録
    {
      path: "/event/:id([0-9]+)/reserve-date/:reserveDateId([0-9]+)",
      component: () => import("@/views/ReserveEdit.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/reserve/history",
      component: () => import("@/views/ReserveHistory.vue"),
      meta: { requiresAuth: true, title: "予約履歴一覧" },
    },
    // 予約詳細
    {
      path: "/reserve-detail/:id([0-9]+)",
      component: () => import("@/views/ReserveDetail.vue"),
      meta: { requiresAuth: true },
    },
    // 作成イベント一覧
    {
      path: "/created-event",
      component: () => import("@/views/CreatedEvent.vue"),
      meta: { requiresAuth: true, title: "作成イベント一覧" },
    },
    // 作成イベント詳細
    {
      path: "/created-event/:id([0-9]+)",
      component: () => import("@/views/EventDetail.vue"),
      meta: { requiresAuth: true, created: true },
    },
    {
      path: "/created-event/:id([0-9]+)/reserve-date/:reserveDateId([0-9]+)",
      component: () => import("@/views/EventReserveDate.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/created-event/:id([0-9]+)/reserve-date/:reserveDateId([0-9]+)/reserve/:reserveId([0-9]+)",
      component: () => import("@/views/ReserveEdit.vue"),
      meta: { requiresAuth: true, created: true },
    },

    {
      path: "/admin/event-owner",
      component: () => import("@/views/admin/EventOwner.vue"),
      meta: { requiresAuthAdmin: true, title: "イベント開催者一覧" },
    },
    {
      path: "/admin/exclude-limit-user",
      component: () => import("@/views/admin/ExcludeLimitUser.vue"),
      meta: { requiresAuthAdmin: true, title: "上限数対象外ユーザー一覧" },
    },
    {
      path: "/admin/event",
      component: () => import("@/views/admin/Event.vue"),
      meta: { requiresAuthAdmin: true, title: "管理者イベント一覧" },
    },
    {
      path: "/admin/admin",
      component: () => import("@/views/admin/Admin.vue"),
      meta: { requiresAuthAdmin: true, title: "システム管理者一覧" },
    },
    {
      path: "/admin/system-config",
      component: () => import("@/views/admin/SystemConfig.vue"),
      meta: { requiresAuthAdmin: true, title: "システム管理" },
    },
    // {
    //   path: "/test",
    //   name: "test",
    //   component: () => import("@/views/Test.vue"),
    // },
    // {
    //   path: "/test/abcd",
    //   name: "test-abcd",
    //   component: () => import("@/views/Test.vue"),
    // },
  ],
});
