import axios from "axios";
import { defineStore } from "pinia";

export const useStore = defineStore({
  id: "store",

  state: () => ({
    me: null,
    environmentName: "",
    nameKeys: [],
    displayStrings: {},
    holidays: [],
    redirectUrl: null,
  }),

  actions: {
    async resetMe() {
      this.me = null;
    },
    async loadMe() {
      const res = await axios.get("/api/auth/me").catch(function (error) {
        console.log("Error", error.message);
      });
      if (res && res?.data) {
        this.me = res.data.me;
        this.environmentName = res.data.environmentName;
        this.nameKeys = res.data?.nameKeys || [];
        this.displayStrings = res.data.displayStrings;
        this.holidays = res.data?.holidays || [];
        this.eventLimit = res.data.eventLimit;
        return this.me;
      }
      return;
    },
  },
});
